$(document).ready(function() {

  	// === Ico Menu Mobile ====
 	$('#ico-menu-nav').click(function(){
		$(this).toggleClass('open');
	});

	/* === Exemplos modal === */
	$('#confirmacao').click(function(){
		$('#pesquisadorAuxiliar').modal('hide');
		$('#confirmacaoModal').modal('show'); 
	});

});
